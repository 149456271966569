import EmailTemplateSelectorNewEditorModal from 'modules/email/components/email-template-selector/email-template-selector-modal'
import { EmailTemplateItemInterface } from 'modules/email/types/email-template-interface'
import { MailingId } from 'modules/mailing/types/mailing-interface'
import React, { useState } from 'react'
import ButtonLikeLink from 'shared/components/button-like-link'
import { ButtonProps } from 'shared/components/button/types/button-interface'
import Confirmation from 'shared/components/confirmation-modal'
import CKEditor from 'shared/components/dashboard-mail-editor/components/ckeditor-helper'
import { SubstituteInterface } from 'shared/components/dashboard-mail-editor/components/substitutes-block'
import {
  MailingPoolStatusColors,
  MailingPoolStatusHelpMessages,
} from 'shared/components/dashboard-mail-editor/enums/mailing-pool-status-enum'
import { useEmailOptions } from 'shared/components/dashboard-mail-editor/hooks/use-email-options'
import { SetCkeditorRefType } from 'shared/components/dashboard-mail-editor/hooks/use-unsaved-email'
import { getEmailStatusLink } from 'shared/components/dashboard-mail-editor/utils/get-email-status-link'
import { isWithRecipient } from 'shared/components/dashboard-mail-editor/utils/is-with-recipients'
import FormInput from 'shared/components/form-input'
import { Hint } from 'shared/components/hint'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import TransLoco from 'shared/components/trans-loco'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import MagicWandIcon from 'shared/icons/magic-wand-icon'
import { twMerge } from 'tailwind-merge'
import { DashboardMailEditorCommonProps, ExtendedState } from '../../types/common'
import { NewEditor } from './new-editor'

export type EditorBlockProps<T extends ExtendedState> = Pick<
  DashboardMailEditorCommonProps<T>,
  'isPreFetching' | 'inModal' | 'errors' | 'tempState' | 'onChangeEmail' | 'disabled'
> & { withRecipient?: boolean } & {
  isSwitchEditorButtonShown?: boolean
  switchEditorButtonProps?: ButtonProps
  isEditorEditable?: boolean
  initValue?: string
  showSettingsLink?: boolean
  setCkeditorRef?: SetCkeditorRefType
  mailingId?: MailingId
  additionalSubstitutes?: SubstituteInterface[]
  className?: string
}

const EditorBlock = <T extends ExtendedState>({
  disabled,
  errors,
  initValue: classicEditorBody,
  inModal,
  isEditorEditable = true,
  isPreFetching,
  isSwitchEditorButtonShown = false,
  onChangeEmail,
  showSettingsLink = true,
  tempState,
  withRecipient,
  setCkeditorRef,
  mailingId,
  additionalSubstitutes,
  className,
}: EditorBlockProps<T>) => {
  const { t } = useLocoTranslation()
  const { user } = useUser()

  const { data: options } = useEmailOptions()

  const [isTemplateModalOpened, setIsTemplateModalOpened] = useState<boolean>(false)
  const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState<boolean>(false)

  const onChooseTemplateModal = async (template: EmailTemplateItemInterface) => {
    tempState && (await onChangeEmail({ ...tempState, useNewEditor: true, template: template.id }))
    setIsTemplateModalOpened(false)
  }
  const onConfirmationModalOpened = async () => {
    tempState && (await onChangeEmail({ ...tempState, useNewEditor: !tempState.useNewEditor }))
    setIsConfirmationModalOpened(false)
  }

  return (
    <div
      className={twMerge(
        `rounded-md bg-white gap-5 w-full flex flex-col`,
        !inModal && 'p-5',
        tempState?.useNewEditor && 'h-[800px] lg:h-fit',
        className,
      )}
    >
      {withRecipient && isWithRecipient(withRecipient, tempState) && (
        <FormInput
          disabled={disabled}
          isPreFetching={isPreFetching}
          label={t('dashboard.email.recipient_address')}
          required
          value={tempState?.recipient || ''}
          error={errors.recipient}
          onChange={e =>
            tempState && onChangeEmail({ ...(tempState as T), recipient: e.target.value })
          }
        />
      )}
      <FormInput
        disabled={disabled}
        isPreFetching={isPreFetching}
        label={
          user &&
          options && (
            <Hint
              color={MailingPoolStatusColors[options.emailPool]}
              label={
                <TransLoco
                  t={t}
                  i18nKey={MailingPoolStatusHelpMessages[options.emailPool]}
                  components={{
                    a: (
                      <a
                        href={getEmailStatusLink(user?.dashboardLocale)}
                        target={'_blank'}
                        rel={'noreferrer'}
                        className={'primary-link'}
                      />
                    ),
                  }}
                />
              }
              popoverClassName={'w-[300px] sm:w-[500px]'}
            >
              {t('dashboard.email.subject')}
            </Hint>
          )
        }
        required
        value={tempState?.subject || ''}
        onChange={e => tempState && onChangeEmail({ ...tempState, subject: e.target.value })}
        error={errors.subject}
      />
      <FormInput
        disabled={disabled}
        isPreFetching={isPreFetching}
        label={
          user &&
          options && (
            <Hint label={t('dashboard.email.preview_text_tooltip')}>
              {t('dashboard.email.preview_text_label')}
            </Hint>
          )
        }
        value={tempState?.previewText || ''}
        error={errors.previewText}
        onChange={e => tempState && onChangeEmail({ ...tempState, previewText: e.target.value })}
      />
      <div className={`text-sm font-medium flex flex-col gap-1 basis-full`}>
        <div className="flex justify-between">
          <span>{t('dashboard.email.body')}</span>
          {isSwitchEditorButtonShown && !isPreFetching && (
            <>
              <ButtonLikeLink
                disabled={disabled}
                onClick={() => {
                  if (tempState) {
                    if (!tempState.useNewEditor && !tempState.html) {
                      !tempState.useNewEditor && setIsTemplateModalOpened(true)
                    } else {
                      setIsConfirmationModalOpened(true)
                    }
                  }
                }}
                className="gap-1"
              >
                <MagicWandIcon className={'w-[14px] h-[14]px'} />
                {tempState?.useNewEditor
                  ? t('dashboard.email.switch_to_the_classic_editor')
                  : t('dashboard.email.switch_to_the_visual_editor')}
              </ButtonLikeLink>
            </>
          )}
        </div>
        <div className="relative h-full">
          {tempState?.useNewEditor && mailingId ? (
            <NewEditor tempState={tempState} isPreFetching={isPreFetching} mailingId={mailingId} />
          ) : (
            <CKEditor
              additionalSubstitutes={additionalSubstitutes}
              isPreFetching={isPreFetching}
              isEditable={!disabled && isEditorEditable}
              isError={errors.common.length !== 0}
              initValue={classicEditorBody || tempState?.body || undefined}
              onChange={data => tempState && onChangeEmail({ ...tempState, body: data })}
              showFooter
              setCkeditorRef={setCkeditorRef}
            />
          )}
        </div>
      </div>
      {showSettingsLink && (
        <span className={'mt-2'}>
          <TransLoco
            t={t}
            i18nKey={'dashboard.email.change_footer_link'}
            components={{
              a: (
                <LinkWithoutPrefetch
                  href={'/profile/mailing-settings'}
                  className={'primary-link'}
                />
              ),
            }}
          />
        </span>
      )}
      {errors.body && <p className="text-sm text-danger">{errors.body}</p>}
      <Confirmation
        opened={isConfirmationModalOpened}
        onClose={() => setIsConfirmationModalOpened(false)}
        onConfirm={onConfirmationModalOpened}
        confirmationText={
          tempState?.useNewEditor
            ? t('dashboard.email.switch_to_the_classic_editor_message')
            : t('dashboard.email.switch_to_the_visual_editor_message')
        }
      />
      <EmailTemplateSelectorNewEditorModal
        opened={isTemplateModalOpened}
        onClose={() => setIsTemplateModalOpened(false)}
        onChoose={onChooseTemplateModal}
      />
    </div>
  )
}
export default EditorBlock
