import React from 'react'
import { useSetDefaultEmailOptions } from 'shared/components/dashboard-mail-editor/hooks/use-set-default-email-options'
import { DisclosureBox } from 'shared/components/disclosure-box'
import { twMerge } from 'tailwind-merge'
import AttachmentsBlock from './components/attachments-block'
import ContactsSettingsBlock from './components/contacts-settings-block'
import EditorBlock from './components/editor-block'
import EmailSettingsBlock from './components/email-settings-block'
import SubstitutesBlock from './components/substitutes-block'
import { DashboardMailEditorProps, ExtendedState } from './types/common'

const DashboardMailEditor = <T extends ExtendedState>({
  additionalSubstitutes,
  attachmentsTempState,
  contactsSettings,
  errors,
  initValue,
  inModal,
  disabled,
  isPreFetching,
  isSwitchEditorButtonShown,
  onChangeContactsSettings,
  onChangeEmail,
  setAttachmentsTempState,
  showSettingsLink,
  sidebarItemBlock,
  switchEditorButtonProps,
  tempState,
  withContactsSettings,
  withRecipient,
  setCkeditorRef,
  mailingId,
  withoutAttachments,
}: DashboardMailEditorProps<T>) => {
  const { isOptionsApplied } = useSetDefaultEmailOptions(tempState, onChangeEmail, {
    withRecipient,
  })

  return (
    <div className={'flex flex-col gap-3'}>
      {errors.common && (
        <>
          {errors.common.map((error, index) => (
            <p className="text-sm text-danger px-6 text-center" key={index}>
              {error}
            </p>
          ))}
        </>
      )}
      <div className={'flex flex-col lg:flex-row gap-5'}>
        <div className={`min-w-0 lg:basis-3/4 lg:h-auto`}>
          <EditorBlock
            additionalSubstitutes={additionalSubstitutes}
            isSwitchEditorButtonShown={isSwitchEditorButtonShown}
            switchEditorButtonProps={switchEditorButtonProps}
            isPreFetching={isPreFetching || !isOptionsApplied}
            disabled={disabled}
            tempState={tempState}
            withRecipient={withRecipient}
            onChangeEmail={onChangeEmail}
            initValue={initValue}
            inModal={inModal}
            errors={errors}
            showSettingsLink={showSettingsLink}
            setCkeditorRef={setCkeditorRef}
            mailingId={mailingId}
          />
        </div>
        <div
          className={`sm:min-w-[320px] lg:basis-1/4 flex flex-col gap-5 static lg:sticky top-5 h-fit`}
        >
          <EmailSettingsBlock
            disabled={disabled}
            isPreFetching={isPreFetching || !isOptionsApplied}
            tempState={tempState}
            onChangeEmail={onChangeEmail}
            inModal={inModal}
            errors={errors}
          />

          {!withoutAttachments && (
            <AttachmentsBlock
              disabled={disabled}
              isPreFetching={isPreFetching}
              attachmentsTempState={attachmentsTempState}
              setAttachmentsTempState={setAttachmentsTempState}
              inModal={inModal}
              mailingId={mailingId}
            />
          )}

          {sidebarItemBlock && (
            <DisclosureBox
              wrapperClassName={twMerge(
                inModal && 'border border-gray/30 rounded-lg',
                sidebarItemBlock.showErrorOutline && 'outline-1 outline outline-danger rounded-lg',
              )}
              title={
                <b className={`text-start text-darkblue text-[17px]`}>{sidebarItemBlock.title}</b>
              }
            >
              {sidebarItemBlock.Component}
            </DisclosureBox>
          )}

          {withContactsSettings && (
            <ContactsSettingsBlock
              disabled={disabled}
              isPreFetching={isPreFetching}
              contactsSettings={contactsSettings}
              onChangeContactsSettings={onChangeContactsSettings}
              inModal={inModal}
            />
          )}
          <SubstitutesBlock inModal={inModal} additionalSubstitutes={additionalSubstitutes} />
        </div>
      </div>
    </div>
  )
}

export default DashboardMailEditor
