import { Disclosure, Transition } from '@headlessui/react'
import { ElementType, PropsWithChildren, ReactNode } from 'react'
import ArrowDownIcon from 'shared/icons/arrow-down-icon'
import { twJoin, twMerge } from 'tailwind-merge'

interface DisclosureBoxProps {
  title: ReactNode
  defaultOpen?: boolean
  buttonClassName?: string
  panelClassName?: string
  wrapperClassName?: string
  buttonAs?: ElementType
}
export function DisclosureBox({
  title,
  children,
  defaultOpen,
  buttonClassName,
  panelClassName,
  wrapperClassName,
  buttonAs,
}: PropsWithChildren<DisclosureBoxProps>) {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <div className={wrapperClassName}>
          <Disclosure.Button
            as={buttonAs}
            className={twMerge(
              'w-full flex justify-between items-center gap-5 bg-white rounded-lg p-5 outline-none focus-visible:ring-2 ring-blue',
              open && 'rounded-b-none border-b border-gray/30',
              buttonClassName,
            )}
          >
            {title}
            <ArrowDownIcon
              width={16}
              height={16}
              className={twJoin(
                'stroke-darkblue transition-transform duration-300',
                open && 'rotate-180',
              )}
            />
          </Disclosure.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform opacity-0 -translate-y-2"
            enterTo="transform opacity-100 translate-y-0"
            leave="transition duration-75 ease-out"
            leaveFrom="transform opacity-100 translate-y-0"
            leaveTo="transform opacity-0 -translate-y-2"
          >
            <Disclosure.Panel
              static
              className={twMerge('p-5 bg-white rounded-lg rounded-t-none', panelClassName)}
            >
              {children}
            </Disclosure.Panel>
          </Transition>
        </div>
      )}
    </Disclosure>
  )
}
