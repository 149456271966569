import { useContactTags } from 'modules/contacts/hooks/use-contact-tags'
import { TagInterface } from 'modules/newsletters/single/types/newsletter-interface'
import React, { useMemo } from 'react'
import { DisclosureBox } from 'shared/components/disclosure-box'
import FormInputIntegerPositiveNumbers from 'shared/components/form-input/form-input-integer-positive-numbers'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twJoin } from 'tailwind-merge'
import { DashboardMailEditorCommonProps } from '../../types/common'
import TagsBlock from './tags-block'

export interface Tags {
  included: TagInterface['id'][]
  excluded: TagInterface['id'][]
}

export interface ContactsSettings {
  tags: Tags
  daysExists: number
}

export interface ContactsSettingsBlockProps
  extends Pick<DashboardMailEditorCommonProps, 'isPreFetching' | 'inModal' | 'disabled'> {
  contactsSettings: ContactsSettings
  onChangeContactsSettings: (value: ContactsSettings) => void
}

const ContactsSettingsBlock = ({
  disabled,
  isPreFetching,
  contactsSettings = { daysExists: 0, tags: { included: [], excluded: [] } },
  onChangeContactsSettings,
  inModal,
}: ContactsSettingsBlockProps) => {
  const { t } = useLocoTranslation()

  const { tags: listOfTags } = useContactTags({ shouldFetch: true })

  const listOfTagsOptions = useMemo(
    () => listOfTags?.items.map(({ id, name }) => ({ id, caption: name })),
    [listOfTags],
  )

  const isLoading = !listOfTags || isPreFetching
  return (
    <DisclosureBox
      defaultOpen
      wrapperClassName={twJoin(inModal && 'border border-gray/30 rounded-lg')}
      title={
        <b className={`text-start text-darkblue text-[17px]`}>
          {t('dashboard.contact_email_settings.block_title')}
        </b>
      }
    >
      <>
        <FormInputIntegerPositiveNumbers
          disabled={disabled}
          isPreFetching={isLoading}
          label={t('dashboard.contact_email_settings.registered_over_input.label')}
          addonBefore={
            <span className="whitespace-nowrap">
              {t('dashboard.contact_email_settings.registered_over_input.registered_over')}
            </span>
          }
          addonAfter={
            <span className="whitespace-nowrap">
              {t('dashboard.contact_email_settings.registered_over_input.days_ago')}
            </span>
          }
          value={contactsSettings.daysExists}
          onChange={value => onChangeContactsSettings({ ...contactsSettings, daysExists: value })}
        />

        <TagsBlock
          type="included"
          title={t('dashboard.contact_email_settings.tags_selector.include_title')}
          disabled={disabled}
          required
          listOfTagsOptions={listOfTagsOptions}
          tagsList={contactsSettings.tags.included}
          isPreFetching={isLoading}
          onChangeMultiselect={data => {
            onChangeContactsSettings({
              ...contactsSettings,
              tags: {
                ...contactsSettings.tags,
                included:
                  listOfTagsOptions
                    ?.filter(({ id }) => (data || []).includes(id))
                    .map(({ id }) => id) || [],
              },
            })
          }}
          onClickTagLabel={currentId =>
            onChangeContactsSettings({
              ...contactsSettings,
              tags: {
                ...contactsSettings.tags,
                included: contactsSettings.tags.included.filter(id => id !== currentId),
              },
            })
          }
          onSelectAll={() =>
            onChangeContactsSettings({
              ...contactsSettings,
              tags: {
                ...contactsSettings.tags,
                included: listOfTagsOptions?.map(({ id }) => id) || [],
              },
            })
          }
          onUnselectAll={() =>
            onChangeContactsSettings({
              ...contactsSettings,
              tags: {
                ...contactsSettings.tags,
                included: [],
              },
            })
          }
        />

        <TagsBlock
          type="excluded"
          title={t('dashboard.contact_email_settings.tags_selector.exclude_title')}
          disabled={disabled}
          listOfTagsOptions={listOfTagsOptions}
          tagsList={contactsSettings.tags.excluded}
          isPreFetching={isLoading}
          onChangeMultiselect={data => {
            onChangeContactsSettings({
              ...contactsSettings,
              tags: {
                ...contactsSettings.tags,
                excluded:
                  listOfTagsOptions
                    ?.filter(({ id }) => (data || []).includes(id))
                    .map(({ id }) => id) || [],
              },
            })
          }}
          onClickTagLabel={currentId =>
            onChangeContactsSettings({
              ...contactsSettings,
              tags: {
                ...contactsSettings.tags,
                excluded: contactsSettings.tags.excluded.filter(id => id !== currentId),
              },
            })
          }
          onSelectAll={() =>
            onChangeContactsSettings({
              ...contactsSettings,
              tags: {
                ...contactsSettings.tags,
                excluded: listOfTagsOptions?.map(({ id }) => id) || [],
              },
            })
          }
          onUnselectAll={() =>
            onChangeContactsSettings({
              ...contactsSettings,
              tags: {
                ...contactsSettings.tags,
                excluded: [],
              },
            })
          }
        />

        <div className={'flex flex-col gap-2'}>
          <span>{t('dashboard.contact_email_settings.tags_selector.note_1')}</span>
          <span>{t('dashboard.contact_email_settings.tags_selector.note_2')}</span>
        </div>
      </>
    </DisclosureBox>
  )
}

export default ContactsSettingsBlock
