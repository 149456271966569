import { MailingId } from 'modules/mailing/types/mailing-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { useControlledSwr } from 'shared/hooks/use-controlled-swr'
import useUser from 'shared/hooks/use-user'

interface AttachmentsPolicyInterface {
  willBeSentAsLinks: boolean
}

export const useAttachmentsPolicy = (mailingId?: MailingId) => {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    AttachmentsPolicyInterface
  >({ method: RequestMethodsEnum.get })

  return useControlledSwr(
    `/api/dashboard/customer/mailing/${mailingId}/attachment-policy`,
    fetcher,
    {},
    !!user && !!mailingId,
  )
}
